import React from 'react';
import Image from '@/components/base/image';
import { IPromoBannerProps } from './types';
import useDeviceType from '@/hooks/useDeviceType';

// const animations = {
//   container: {
//     hidden: {
//       opacity: 0,
//     },
//     show: {
//       opacity: 1,
//       transition: {
//         staggerChildren: 0.3,
//         delayChildren: 0.15,
//         duration: 0.3,
//       },
//     },
//   },
//   item: {
//     hidden: {
//       x: '-50%',
//       opacity: 0,
//     },
//     show: {
//       x: 0,
//       opacity: 1,
//       transition: {
//         duration: 0.3,
//       },
//     },
//   },
// };

const PromoBanner: React.FC<IPromoBannerProps> = ({
  children,
  backgroundColor,
  desktopImage,
  mbDesktop = 100,
  mtDesktop = 100,
  mtLaptop = 100,
  mbLaptop = 100,
  mbMobile = 30,
  mtMobile = 30,
}) => {
  const deviceType = useDeviceType();

  const style = {
    '--bg': backgroundColor,
    marginTop:
      deviceType === 'mobile'
        ? mtMobile
        : deviceType === 'laptop' || deviceType === 'tablet'
        ? mtLaptop
        : mtDesktop,
    marginBottom:
      deviceType === 'mobile'
        ? mbMobile
        : deviceType === 'laptop' || deviceType === 'tablet'
        ? mbLaptop
        : mbDesktop,
  } as React.CSSProperties;

  return (
    <section
      className="promo-banner"
      // variants={animations.container}
      // initial="hidden"
      // whileInView="show"
      // viewport={{ once: true, amount: 0.5 }}
      style={style}
    >
      <div className="promo-banner-space" />
      <div
        className="promo-banner-image"
        //  variants={animations.item}
      >
        <Image {...desktopImage} alt="good image" />
      </div>
      <div
        className="promo-banner-container"
        //  variants={animations.item}
      >
        {children}
      </div>
    </section>
  );
};

export default PromoBanner;
